import React from 'react';
import './About.css';
import Slider from '../Home/Slider/Slider';
import VisionMission from '../About/VisionMission/VisionMission';
import OurPartner from '../About/OurPartner/OurPartner';
import OurClient from '../About/OurClient/OurClient';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../i18n.js';
import { useRevealUp } from '../../components/Animation/RevealUp/RevealUp.js'; 

const About = () => {
    const { t } = useTranslation();
    useRevealUp('.revealUp');

    return (    
        <div className="">
            <Slider />
            <div className="description flex flex-col items-center mx-8 md:mx-18 revealUp mb-10">
                <div className="rectangle w-[10rem] h-1 md:h-3 border-4 border-red-800 bg-red-800"></div>
                <div className="m-2 mt-10 flex items-center text-[4rem] font-bold text-nowrap">
                    <p className="text-black text-2xl sm:text-4xl md:text-5xl lg:text-5xl">Artek Sinergi</p>
                    <p className="text-red-500 ml-2 text-2xl sm:text-4xl md:text-5xl lg:text-5xl">Multimedia</p>
                </div>
                <div className="text-center mt-10 text-xl">
                    {t('about_content_business')}
                </div>
                <div className="text-center mt-10 text-xl">
                    {t('about_content_competitive')}
                </div>
                <div className="text-center mt-10 text-xl">
                    {t('about_content_maintenance')}
                </div>
            </div>
            <VisionMission />
            <OurPartner />
            <OurClient />
            <div className="flex flex-col items-center justify-center px-6 py-8 sm:px-8 sm:py-10 md:px-10 md:py-10 border-4 border-red-600 rounded-3xl mx-7 sm:mx-12 md:mx-24 my-8  sm:my-8 md:my-16">
                <p className="text-black font-bold text-2xl sm:text-3xl md:text-5xl text-center" style={{ lineHeight: '1.3' }}>
                    {t('about_content_contact')}
                </p>
                <Link className="mt-4 sm:mt-5 lg:mt-6 buttonContact text-xs sm:text-sm md:text-base lg:text-lg px-3 py-1 sm:px-4 sm:py-2 lg:px-6 lg:py-3" to='/contact'>
                    {t('about_content_contact_button')}
                </Link>
            </div>
        </div>
    );
};

export default About;