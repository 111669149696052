import IconWhatsapp from "./assets/whatsapp.png";
import "./App.css";
import "./index.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Loading from "./components/Loading/Loading";
import Home from "./views/Home/Home";
import Contact from "./views/Contact/Contact";
import About from "./views/About/About";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      {/* Scroll to top component */}
      <ScrollToTop />

      {/* Loading Component */}
      <Loading />

      {/* Navbar */}
      <Navbar />

      {/* Routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
      </Routes>

      {/* WhatsApp Floating Button */}
      <a
        href="https://wa.me/6285215774466"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="btn-whatsapp fixed-whatsapp">
          <img
            src={IconWhatsapp}
            className="header-cart"
            alt="WhatsApp Icon"
            width="70"
          />
        </div>
      </a>

      {/* Footer */}
      <Footer />
    </Router>
  );
}

export default App;
