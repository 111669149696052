import React from 'react';
import './OurPartner.css';
import partner from '../../../assets/home/partners/adobe.png';
import partner2 from '../../../assets/home/partners/anycubic.png';
import partner3 from '../../../assets/home/partners/biznet.png';
import partner4 from '../../../assets/home/partners/creo.png';
import partner5 from '../../../assets/home/partners/google.png';
import partner6 from '../../../assets/home/partners/intel.png';
import partner7 from '../../../assets/home/partners/nvidia.svg';
import partner8 from '../../../assets/home/partners/solid_work.png';
import partner9 from '../../../assets/home/partners/microsoft.png';
import { useTranslation } from 'react-i18next';
import '../../../i18n.js';
import { useRevealUp } from '../../../components/Animation/RevealUp/RevealUp.js'; 

const OurPartner = () => {
    const { t } = useTranslation();
    useRevealUp('.revealUp');

    return (    
        <div className="description flex flex-col flex-wrap items-center mt-10 md:mx-20">
            <div className="rectangle w-[10rem] h-1 md:h-3 border-4 border-red-800 bg-red-800"></div>
            <div className="m-2 mt-10 flex items-center text-[4rem] font-bold revealUp">
                <p className="text-black text-4xl md:text-5xl lg:text-5xl">{t('our_partner_title_up')}</p>
                <p className="text-red-500 ml-2 text-4xl md:text-5xl lg:text-5xl">{t('our_partner_title_down')}</p>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-10 object-center justify-center items-center">
                <div>
                    <img className="image m-8" src={partner5} alt=""/>
                </div>
                <div>
                    <img className="image m-8" src={partner} alt="" />
                </div>
                <div>
                    <img className="image m-8" src={partner8} alt=""/>
                </div>
                <div>
                    <img className="image m-8" src={partner4} alt=""/>
                </div>
                <div>
                    <img className="image m-8" src={partner9} alt=""/>
                </div>
                <div>
                    <img className="image m-8" src={partner6} alt=""/>
                </div>
                <div>
                    <img className="image m-8" src={partner7} alt=""/>
                </div>
                <div>
                    <img className="image m-8" src={partner3} alt=""/>
                </div>
                <div>
                    <img className="image m-8" src={partner2} alt=""/>
                </div>
            </div>

        </div>
    );
};

export default OurPartner;