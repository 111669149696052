import React from 'react';
import partner from '../../../assets/home/partners/adobe.png';
import partner2 from '../../../assets/home/partners/anycubic.png';
import partner3 from '../../../assets/home/partners/biznet.png';
import partner4 from '../../../assets/home/partners/creo.png';
import partner5 from '../../../assets/home/partners/google.png';
import partner6 from '../../../assets/home/partners/intel.png';
import partner7 from '../../../assets/home/partners/nvidia.svg';
import partner8 from '../../../assets/home/partners/solid_work.png';
import './Partner.css';
import { useTranslation } from 'react-i18next';
import '../../../i18n.js';
import { useRevealUp } from '../../../components/Animation/RevealUp/RevealUp.js'; 

export default function Partner() {
  const { t } = useTranslation();
  useRevealUp('.revealUp');

    return (
        <div className="flex flex-col items-center">
            <div className="rectangle w-[10rem] h-1 md:h-3 border-4 border-red-800 bg-red-800"></div>
            <div className="m-2 mt-10 flex items-center text-[4rem] font-bold revealUp">
                <p className="text-black text-4xl md:text-5xl lg:text-5xl">{t('partner_title_1')}</p>
                <p className="text-red-500 ml-2 text-4xl md:text-5xl lg:text-5xl">{t('partner_title_2')}</p>
            </div>
            <div className="sliderPartner">
                <div className="slide-track-partner">
                    <div className="slide-partner">
                        <img src={partner} height="180" width="300" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner2} height="180" width="100" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner3} height="180" width="200" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner4} height="180" width="300" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner5} height="180" width="300" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner6} height="180" width="200" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner7} height="180" width="300" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner8} height="180" width="150" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner} height="180" width="300" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner2} height="180" width="100" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner3} height="180" width="200" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner4} height="180" width="300" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner5} height="180" width="300" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner6} height="180" width="200" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner7} height="180" width="300" alt=""/>
                    </div>
                    <div className="slide-partner">
                        <img src={partner8} height="180" width="150" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
}
