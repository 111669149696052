import React from 'react';
import value from '../../../assets/home/value/R&D.png';
import value2 from '../../../assets/home/value/hadnshake.png';
import value3 from '../../../assets/home/value/innovation.png';
import value4 from '../../../assets/home/value/team.png';
import './Value.css';
import { useTranslation } from 'react-i18next';
import '../../../i18n.js';
import { useRevealUp } from '../../../components/Animation/RevealUp/RevealUp.js'; 

export default function Value() {
    const { t } = useTranslation();
    useRevealUp('.revealUp');

    return (
        <div className="flex flex-col items-center">
            <div className="rectangle w-[10rem] h-1 md:h-3 border-4 border-red-800 bg-red-800"></div>
            <div className="m-2 mt-10 flex items-center font-bold revealUp">
                <p className="text-black text-4xl md:text-5xl lg:text-5xl">{t('value_title_1')}</p>
                <p className="text-red-500 ml-2 text-4xl md:text-5xl lg:text-5xl">{t('value_title_2')}</p>
            </div>
            <div className="container mt-5 flex justify-center items-center ">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
                    <div className="card value bg-white border border-gray-200 items-center" style={{ backgroundColor: '#ae5dd8' }}>
                        <img src={value} alt="Value 2" style={{ backgroundColor: '#c56cf2' }}/>
                        <div className="p-4">
                            <h3 className="font-semibold text-center text-white text-2xl">{t('value_content_purple')}</h3>
                        </div>
                    </div>
                    <div className="card value bg-white border border-gray-200 items-center" style={{ backgroundColor: '#d5434d' }}>
                        <img src={value2} alt="Value 2" style={{ backgroundColor: '#f26c75' }}/>
                        <div className="p-4">
                            <h3 className="font-semibold text-center text-white text-2xl">{t('value_content_red')}</h3>
                        </div>
                    </div>
                    <div className="card value bg-white border border-gray-200 items-center" style={{ backgroundColor: '#41cd9a' }}>
                        <img src={value3} alt="Value 2" style={{ backgroundColor: '#62f6c0' }}/>
                        <div className="p-4">
                            <h3 className="font-semibold text-center text-white text-2xl">{t('value_content_green')}</h3>
                        </div>
                    </div>
                    <div className="card value bg-white border border-gray-200 items-center" style={{ backgroundColor: '#00948b' }}>
                        <img src={value4} alt="Value 2" style={{ backgroundColor: '#8ee0da' }}/>
                        <div className="p-4">
                            <h3 className="font-semibold text-center text-white text-2xl">{t('value_content_tosca')}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
