// import React, { useState, useEffect } from 'react';
// import logo_putih from '../../assets/navbar/logo_putih.png';
// import logo_merah from '../../assets/navbar/logo_merah.png';
// import './Navbar.css';
// import { Link } from 'react-router-dom';

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };
  
//   const [showNavbar, setShowNavbar] = useState(false);
//   const scrollFunction = () => {
//     if (window.scrollY > 20) {
//       setShowNavbar(true);
//     } else {
//       setShowNavbar(false);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', scrollFunction);
//     return () => {
//       window.removeEventListener('scroll', scrollFunction);
//     };
//   }, []);

//   return (
//     <nav className={`navbar p-4 sticky top-0 z-50 ${showNavbar ? 'bg-blur-navbar' : 'bg-navbar'}`}>
//       <div className="container mx-auto flex items-center justify-between ml-12 mr-8">
//         <Link to="/" className="text-white text-2xl font-bold flex items-center" rel="noopener noreferrer">
//           <img src={showNavbar ? logo_merah : logo_putih} alt="Logo" className="artek-logo mr-2" />
//         </Link>
        
//         <button 
//           className="text-white md:hidden" 
//           onClick={toggleMenu}
//         >
//           <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}></path>
//           </svg>
//         </button>

//         <div className={`flex ml-auto items-center font-bold text-xl space-x-8 text-white ${isOpen ? 'block' : 'hidden'} md:flex`}>
//           <Link to="/" className={`hover:text-red-200 ${showNavbar ? 'text-black' : ''}`}>
//             Home
//           </Link>
//           <Link to="/about" className={`hover:text-red-200 ${showNavbar ? 'text-black' : ''}`}>
//             About
//           </Link>

//           <div className="relative inline-block text-left">
//             <select className="block w-full bg-white text-black py-2 px-4 pr-8 rounded-[12px] leading-tight focus:outline-none">
//               <option>Eng</option>
//               <option>Ind</option>
//             </select>
//           </div>
          
//           <Link className="btn-contact-us text-white py-2 px-4 rounded-[20px] hover:bg-red-800" to="/contact">Contact Us</Link>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;


import React, { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import './Navbar.css';
import logo_putih from '../../assets/navbar/logo_putih.png';
import logo_merah from '../../assets/navbar/logo_merah.png';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../i18n.js'; 

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };

  const [showNavbar, setShowNavbar] = useState(false);
  const scrollFunction = () => {
    if (window.scrollY > 20) {
      setShowNavbar(true);
    } else {
      setShowNavbar(false);
    }
  };
    useEffect(() => {
    window.addEventListener('scroll', scrollFunction);
    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, []);

  return (
    <div className={`navbar sticky z-50 flex justify-between items-center h-24 mx-auto px-4 md:px-24 font-bold ${showNavbar ? 'text-black' : 'text-white'} ${showNavbar ? 'bg-blur-navbar' : 'bg-navbar'}`}>
      {!nav ? (
        <Link to="/">
          <img src={showNavbar ? logo_merah : logo_putih} alt="Logo" className="artek-logo ml-6" />
        </Link>
      ) : (
        <div alt="Logo" className="artek-logo ml-2"></div>
      )
      }
      {/* <img src={showNavbar ? logo_merah : logo_putih} alt="Logo" className="artek-logo ml-2" /> */}
      {/* <h1 className='w-full text-3xl font-bold text-[#00df9a]'>REACT.</h1> */}

      {/* Desktop Navigation */}
      <ul className='hidden md:flex ml-auto justify-center items-center'>
        {/* {navItems.map(item => (
          <li key={item.id} className='p-4 hover:bg-[#00df9a] rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>
            {item.text}
          </li>
        ))} */}
          <Link to="/" className={`hover:text-red-200 per-menu ${showNavbar ? 'text-black' : ''}`}>
            <li className="p-4 rounded-xl m-2 cursor-pointer duration-300 hover:text-red-200">
              {t('navbar_home')}
            </li>
          </Link>
          <Link to="/about" className={`hover:text-red-200 per-menu ${showNavbar ? 'text-black' : ''}`}>
            <li className="p-4 rounded-xl m-2 cursor-pointer duration-300 hover:text-red-200">
              {t('navbar_about')}
            </li>
          </Link>
          <li className="p-4 rounded-xl m-2 cursor-pointer duration-300 hover:text-red-200">
            <select onChange={(e) => changeLanguage(e.target.value)} className="bg-white text-black px-4 rounded-[12px] leading-tight focus:outline-none per-menu ">
              <option value="eng">Eng</option>
              <option value="idn">Ind</option>
            </select>
          </li>
          <li className="rounded-xl cursor-pointer duration-300" style={{width: "max-content"}}>
            {!showNavbar ? (
              <Link className={`${!showNavbar ? 'btn-glow' : ''}`} to="/contact">
                <span className={`${!showNavbar ? 'btnInner' : ''}`}>{t('navbar_contactus')}</span>
              </Link>
            ):(
              <Link className="btn-contact-us text-white rounded-[12px] hover:bg-red-800" to="/contact">{t('navbar_contactus')}</Link> 
            )}
          </li>
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? `fixed md:hidden left-0 top-0 w-[60%]  border-r border-r-gray-900 ${showNavbar ? 'bg-blur-navbar' : 'bg-navbar'} ease-in-out duration-500`
            : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
        }
      >
        {/* Mobile Logo */}
        <img src={showNavbar ? logo_merah : logo_putih} alt="Logo" className="artek-logo ml-4 mt-4" />
        {/* <h1 className='w-full text-3xl font-bold text-[#00df9a] m-4'>REACT.</h1> */}

        {/* Mobile Navigation Items */}
        {/* {navItems.map(item => (
          <li key={item.id} className={`p-4 border-b rounded-xl ${showNavbar ? 'bg-blur-navbar' : 'bg-navbar'} hover:text-red-600 duration-300 hover:text-black cursor-pointer border-gray-600`}>
            {item.text}
          </li>
        ))} */}
          <Link className="" to="/">
            <li className={`p-4 border-b rounded-xl ${showNavbar ? 'bg-blur-navbar' : 'bg-navbar'} hover:text-red-600 duration-300 hover:text-black cursor-pointer border-gray-600`}>
              {t('navbar_home')}
            </li>
          </Link>
          <Link className="" to="/about">
            <li className={`p-4 border-b rounded-xl ${showNavbar ? 'bg-blur-navbar' : 'bg-navbar'} hover:text-red-600 duration-300 hover:text-black cursor-pointer border-gray-600`}>
              {t('navbar_about')}
            </li>
          </Link>
          <li className={`p-4 border-b rounded-xl ${showNavbar ? 'bg-blur-navbar' : 'bg-navbar'} hover:text-red-600 duration-300 hover:text-black cursor-pointer border-gray-600`}>
              <select onChange={(e) => changeLanguage(e.target.value)} className="bg-white text-black px-4  rounded-[12px] leading-tight focus:outline-none">
                <option value="eng">Eng</option>
                <option value="idn">Ind</option>
              </select>
          </li>
          <Link className="" to="/contact">
            <li className={`p-4 border-b rounded-xl ${showNavbar ? 'bg-blur-navbar' : 'bg-navbar'} hover:text-red-600 duration-300 hover:text-black cursor-pointer border-gray-600`}>
              {t('navbar_contactus')}
            </li>
          </Link>
      </ul>
    </div>
  );
};

export default Navbar;