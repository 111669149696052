import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; 
import logo_merah from '../../assets/navbar/logo_merah.png';
import './Loading.css';

const Loading = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location) {
      setLoading(true);

      const timeout = setTimeout(() => {
        setLoading(false);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [location]);

  return (
    <div className={`loading-overlay ${loading ? "active" : ""}`}>
      <img
        src={logo_merah}
        alt="Loading"
        className="loading-image"
      />
    </div>
  );
};

export default Loading;
