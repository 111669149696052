import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationENG from './locales/eng/translation.json';
import translationIDN from './locales/idn/translation.json';

const resources = {
  eng: {
    translation: translationENG,
  },
  idn: {
    translation: translationIDN,
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'eng',
    fallbackLng: 'eng',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
