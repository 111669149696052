import Slider from "../Home/Slider/Slider";
import Partner from "../Home/Partner/Partner";
import Value from "../Home/Value/Value";
import Product from "../Home/Product/Product";
import Service from "../Home/Service/Service";
import Customer from "../Home/Customer/Customer";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../../i18n.js";

import "./Home.css";

export default function App() {
  const { t } = useTranslation();
  return (
    <div>
      <Slider />
      <Partner />
      <Value />
      <Product />
      <Service />
      <Customer />
      <div className="flex flex-col items-center justify-center px-6 py-8 sm:px-8 sm:py-10 md:px-10 md:py-10 border-4 border-red-600 rounded-3xl mx-7 sm:mx-12 md:mx-24 my-8  sm:my-8 md:my-16">
        <p
          className="text-black font-bold text-2xl sm:text-3xl md:text-5xl text-center"
          style={{ lineHeight: "1.3" }}
        >
          {t("home_contact_desc")}
        </p>
        {/* <Link to="/contact">
          <a className="mt-4 sm:mt-5 lg:mt-8 buttonContact text-xs sm:text-sm md:text-base lg:text-lg px-3 py-1 sm:px-4 sm:py-2 lg:px-6 lg:py-3">
            {t("home_contact_us")}
          </a>
        </Link> */}
        <Link to="/contact">
          <button className="mt-4 sm:mt-5 lg:mt-8 buttonContact text-xs sm:text-sm md:text-base lg:text-lg px-3 py-1 sm:px-4 sm:py-2 lg:px-6 lg:py-3">
            {t("home_contact_us")}
          </button>
        </Link>
      </div>
    </div>
  );
}
