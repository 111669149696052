import React from 'react';
import './VisionMission.css';
import logo_merah from '../../../assets/navbar/logo_merah.png';
import { useTranslation } from 'react-i18next';
import '../../../i18n.js';
import { useRevealUp } from '../../../components/Animation/RevealUp/RevealUp.js'; 

const VisionMission = () => {
    const { t } = useTranslation();
    useRevealUp('.revealUp');

    return (    
        <div className="bg-gradient-to-b from-red-400 to-red-800">
            <div className="flex flex-wrap text-lg">
                <div className="w-full md:mx-10 lg:mx-40">
                    <div className="flex flex-col md:flex-row md:items-start md:space-x-10">
                        <div className="w-full md:w-2/5 flex justify-center items-center text-center my-12 sm:my-12 md:my-12">
                            <div className="relative bulat-footer bg-purple-50 rounded-full flex justify-center items-center text-center p-5 shadow-xl">
                                <img src={logo_merah} alt="Logo" className="artek-logo-footer" />
                            </div>
                        </div>
                        <div className="w-full md:w-3/5 my-12">
                            <div className="rectangle md:w-[10rem] h-1 md:h-3 bg-white justify-center md:justify-normal"></div>
                            <div className="m-2 mt-10 flex justify-center md:justify-normal items-center text-[4rem] font-bold revealUp">
                                <p className="text-black text-4xl md:text-5xl lg:text-5xl">{t('vision_title_up_1')}</p>
                                <p className="text-white ml-2 text-4xl md:text-5xl lg:text-5xl">{t('vision_title_up_2')}</p>
                            </div>
                            <div className="text-white mx-10 md:mx-0 revealUp">
                                {t('vision_content')}
                            </div>

                            <div className="rectangle md:w-[10rem] h-1 md:h-3 bg-white mt-10"></div>
                            <div className="m-2 mt-10 flex justify-center md:justify-normal items-center text-[4rem] font-bold revealUp">
                                <p className="text-black text-4xl md:text-5xl lg:text-5xl">{t('vision_title_down_1')}</p>
                                <p className="text-white ml-2 text-4xl md:text-5xl lg:text-5xl">{t('vision_title_down_2')}</p>
                            </div>
                            <div className="text-white mx-10 md:mx-0 revealUp">
                                {t('vision_content_1')}
                                <br></br>
                                {t('vision_content_2')}
                                <br></br>
                                {t('vision_content_3')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default VisionMission;